body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #ebebeb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #09d3ac;
}

.row {
}
.col {
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  _font-family: "courier new", monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
small {
  font-size: 85%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}
dd {
  margin: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
label {
  cursor: pointer;
}
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
  *overflow: visible;
}
table button,
table input {
  *overflow: auto;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
#topbar {
  height: 60px;
  display: block;
  position: relative;
  margin-bottom: 40px;
}
#container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}
.left {
  text-align: left;
}
.right {
}
#main {
  border-bottom: solid 1px #dcdcde;
  padding-bottom: 40px;
}
#main .left {
  text-align: center;
}
#secondary {
  border-top: 1px solid white;
  padding-top: 20px;
  margin-bottom: 40px;
}
#secondary ul {
  margin-bottom: 40px;
}
img.logo {
  max-width: 202px;
  margin: 0 auto;
  width: 202px;
  display: block;
  margin-bottom: 20px;
}
#appIntro {
  text-align: center;
}
#appStore img {
  margin: 20px 0;
}




li{text-align: left; color:#21b5ba;}
li p{color:#4e5151;}
li p span{font-weight:bold;color:#21b5ba;text-transform:uppercase;}
.discussionSpan { 
  color: #49a9d9;
}



.h3highlights {
  font-weight: 300;
  font-size: 40px;
}

h3 {
  font-family: proxima-nova-extra-condensed, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 40px;
  color: #2c2c2c;
  letter-spacing: -2px;
  margin: 0 0 20px 0;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}
h2#grabber, h2#grabberDiscussion {
  color: #21b5ba;
  font-family: proxima-nova-extra-condensed, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 50px;
  letter-spacing: -1px;
  line-height: 50px;
  margin-bottom: 20px;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}
h2#grabberDiscussion {
  color: #49a9d9;
}
p.grabberText {
  font-size: 22px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
p.follow {
  text-transform: uppercase;
  font-family: proxima-nova-extra-condensed, sans-serif;
  color: #ced0d0;
  font-size: 30px;
}
a.twitterIcon {
  background-image: url("/static/images/iconTwitter.png");
  display: inline-block;
  text-indent: -9999px;
  width: 32px;
  height: 32px;
  background-size: contain;
}
a.facebookIcon {
  background-image: url("/static/images/iconFacebook.png");
  display: inline-block;
  text-indent: -9999px;
  width: 32px;
  height: 32px;
  background-size: contain;
}
a.facebookIcon,
a.twitterIcon {
  margin-left: 3px;
  position: relative;
  top: 5px;
}
a.facebookIcon:hover,
a.twitterIcon:hover {
  position: relative;
}
#supportLink {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: #26b9bd;
  background-image: -ms-linear-gradient(top, #26b9bd, #157b7e);
  border: solid 1px #2dd7db;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 1px 1px 4px #126163;
  -webkit-box-shadow: 1px 1px 3px #126163;
  -mox-box-shadow: 1px 1px 4px #126163;
}
#supportLink:visited {
  color: white;
}
.fancybox-outer {
  color: #fff !important;
  background: #2f2f2e;
}
.fancybox-outer h3 {
  color: #21b5ba;
}
.addthis_toolbox {
  margin: 20px auto;
}
#tender_toggler_link {
  background: #21b5ba !important;
}
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}
.ir br {
  display: none;
}
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
@media only screen and (max-device-width: 480px) {
  #topbar {
    margin-bottom: 10px;
  }
  body {
    font-size: 90%;
  }
  p.grabberText {
    font-size: 18px;
  }
  h3 {
    font-size: 33px;
  }
  #screenshots {
    width: 320px;
    padding: 0;
    margin: 0 auto;
    position: relative;
    left: -20px;
  }
  .left {
    width: 100%;
    float: none;
  }
  .right {
    width: 100%;
    float: none;
  }
  img.logo {
    width: 150px;
  }
  h2#grabber {
    font-size: 38px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 480px) {
}
@media only screen and (max-width: 768px) {
}
@media screen and (orientation: portrait) {
}
@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
            filter: none !important;
    -ms-filter: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/*
playback timings (ms):
  exclusion.robots: 0.269
  RedisCDXSource: 1.937
  CDXLines.iter: 12.043 (3)
  LoadShardBlock: 388.707 (3)
  exclusion.robots.policy: 0.253
  load_resource: 194.041
  PetaboxLoader3.resolve: 488.592 (2)
  captures_list: 405.871
  esindex: 0.011
  PetaboxLoader3.datanode: 40.309 (4)
*/

.appMain {
  padding-top:55px;
}

body {
  background-color: #e9e9e9;
}

.appRowMainTop {
  border-bottom: 1px solid #dcdcd3;
  padding-bottom: 25px;
}

.appRowMainBottom {
  border-top: 1px solid white;
  padding-top: 20px;
}

.AppSummary {
  background-color: #ebebeb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: red;
}

.appDescription {
}

img.logo {
    max-width: 300px;
    width: 300px;
    padding-right: 35px;
}
.appFeatureList {
}

.appScreens {
}

.phoneScreenshot {
    width: auto;
    height: 80vh;
  }
.appPrivacyPolicy {
    text-align: left;
}

.phone {
  width: auto;
  height: 80vh;
}

.Header {
}

.SupportButton {
  background-color: transparent !important;
  border: 2px solid #cecece;
}

.DiscussionHeader {
  background-color: #49a8d9 !important;
}

.LetsTalkHeader {
  background-color: #439993 !important;
}

