.appMain {
  padding-top:55px;
}

body {
  background-color: #e9e9e9;
}

.appRowMainTop {
  border-bottom: 1px solid #dcdcd3;
  padding-bottom: 25px;
}

.appRowMainBottom {
  border-top: 1px solid white;
  padding-top: 20px;
}

.AppSummary {
  background-color: #ebebeb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: red;
}
